@use '@globals' as g;

// @import url(//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

*,
*::before,
*::after {
  box-sizing: border-box;
}

img,
picture {
  border-style: none;
  max-width: 100%;
  height: auto;
}

a {
  color: var(--color-base);
  text-decoration: none;
  cursor: pointer;
}

p + p {
  margin-top: 2em;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
  touch-action: manipulation;
  font-size: 62.5%;
}

body {
  background: var(--color-bgBase);
  color: var(--color-primary);
  // font-family: g.$robot;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8;
  min-width: 375px;
  @include g.media('md') {
    font-size: 1.6rem;
  }
}

main {
  overflow: hidden;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 57px;
  }
  // & > section:nth-child(1) {
  //   margin-top: 100px;
  // }
  // & > section.hero-section:nth-child(1) {
  //   margin-top: unset;
  // }
}
@media screen and (min-width: 768px) {
  .pc-none {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .sp-none {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .minPc-none {
    display: none;
  }
  .minPc-only {
    display: initial;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-none {
    display: none;
  }
  .tab-only {
    display: initial;
  }
}
.hero-section {
  .swiper-pagination {
    top: 0!important;
    right: 20px!important;
    bottom: 0!important;
    left: initial!important;
    width: 15px!important;
    height: 100px!important;
    margin: auto!important;
  }
  .swiper-pagination-bullet {
    box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.6);
    background: var(--color-white)!important;
    border: 2px solid transparent!important;
    opacity: 1 !important;
    width: 12px;
    height: 12px;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: none !important;
    border: 2px solid var(--color-white)!important;
  }
}
.scrollSlider__voice {
  .swiper {
    padding-bottom: g.vw(30);
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: url('/svg/icon_swiper_arrow.svg') no-repeat;
    background-size: 100%;
    width: g.vw(26);
    height: g.vw(26);
    &::after {
      content: none;
    }
  }
  .swiper-button-prev {
    transform: rotate(180deg);
  }
  .swiper-pagination {
    bottom: 0!important;
    span {
      background-color: var(--color-blue);
      width: 9px !important;
      height: 9px !important;
      opacity: 1 !important;
    }
    .swiper-pagination-bullet-active {
      background: none;
      border: 2px solid var(--color-blue);
      width: 12px !important;
      height: 12px !important;
      transform: translateY(1.5px);
    }
  }
}
.riyu-green-text {
  color: var(--color-green);
}
.accident-green-text {
  color: var(--color-green);
  font-weight: 700;
  font-size: g.vw(13);
  @include g.media('md') {
    font-size: g.vw(10);
  }
  @include g.media('lg') {
    font-size: 24px;
  }
}
.accident-yellow-text {
  color: var(--color-yellow);
  font-weight: 700;
  font-size: g.vw(16);
  @include g.media('md') {
    font-size: g.vw(12);
  }
  @include g.media('lg') {
    font-size: 34px;
  }
}
.kome {
  font-size: g.vw(12);
  @include g.media('md') {
    font-size: g.vw(6);
  }
  @include g.media('lg') {
    font-size: 14px;
  }
}
.txt-c {
  text-align: center;
}
.scrollSlider__image__swiper .swiper {
  overflow: initial;
}
.lineLink a {
  background-color: var(--color-line)!important;
}