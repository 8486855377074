@use '@globals' as g;

.hamburger_items {
  padding: g.vw(10) g.vw(20) g.vw(20);
  @include g.media('lg') {
    align-items: center;
  }

  li {
    border-bottom: 1px solid #0c20b7;
    border-right: 1px solid #0c20b7;
    & + li {
      margin-top: g.vw(3);
    }
    a {
      display: block;
      font-weight: 700;
      padding: g.vw(10) g.vw(10) g.vw(10) g.vw(15);
      position: relative;
      text-decoration: none!important;
      transition: all .2s ease-out;
      &::after {
        border-top: 2px solid var(--color-white);
        border-right: 2px solid var(--color-white);
        content: '';
        display: block;
        width: g.vw(5);
        height: g.vw(5);
        transform: rotate(45deg);
        margin: auto;
        position: absolute;
        right: g.vw(10);
        top: 0;
        bottom: 0;
      }
    }
  }
}