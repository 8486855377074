@use '../globals/' as g;

// 

:root {
  // ベース
  @each $key, $color in g.$theme-colors {
    --color-#{$key}: #{$color};
  }

  // coolテーマ
  &[data-theme='cool'] {
    @each $key, $color in g.$cool-theme-colors {
      --color-#{$key}: #{$color};
    }
  }

  // naturalテーマ
  &[data-theme='natural'] {
    @each $key, $color in g.$natural-theme-colors {
      --color-#{$key}: #{$color};
    }
  }
  
  // popテーマ
  &[data-theme='pop'] {
    @each $key, $color in g.$pop-theme-colors {
      --color-#{$key}: #{$color};
    }
  }

  // --color-primary: #1823CE;
  --color-white: #fff;
  --color-gray: #f3f3f3;
  --color-black: #000;
  --color-waterBlue: #189BEF;
}