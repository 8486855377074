@charset 'urt-8';
// font-size
$default_fz: 18;

// htmlタグのdata-theme属性にbase, cool, natural, popを指定してください。
// baseを指定、またはdata-theme属性を指定しない場合は、$theme-colorsの値が適用されます。
$theme-colors: (
  primary: #333,
  secondary: #b70000,
  success: #2359A1,
  warning: #f8c11a,
  danger: #b70000,
  background: #1D35DA,
  base: #fff,
  bgBase: #FAFAF8,
  white: #fff,
  green: #14C473,
  yellow: #E5D519,
  blue: #1D35DA,
  waterBlue: #189BEF,
  red: #b70000,
  gray: #fbfbfb,
  line: #00B900,
);

$cool-theme-colors: (
  primary: #2359A1,
  secondary: #333,
  success: #2359A1,
  warning: #b70000,
  danger: #b70000,
  background: #e9e9e9,
  base: #fff,
);

$natural-theme-colors: (
  primary: #16b43b,
  secondary: #f8c11a,
  success: #2359A1,
  warning: #b70000,
  danger: #b70000,
  background: #e9e9e9,
  base: #f7f7f7,
);

$pop-theme-colors: (
  primary: #f8c11a,
  secondary: #333,
  success: #2359A1,
  warning: #b70000,
  danger: #b70000,
  background: #e9e9e9,
  base: #f7f7f7,
);

// ================
// margin (PX)
// ================
$marginPx__XS: 10px;
$marginPx__SM: 15px;
$marginPx__MD: 30px;
$marginPx__LG: 50px;
$marginPx__XL: 100px;

// ================
// margin (vw)
// ================
$marginVw__XS: 1vw;
$marginVw__SM: 3vw;
$marginVw__MD: 5vw;
$marginVw__LG: 10vw;
$marginVw__XL: 15vw;

// ================
// padding (PX)
// ================
$paddingPx__XS: 10px;
$paddingPx__SM: 15px;
$paddingPx__MD: 30px;
$paddingPx__LG: 50px;
$paddingPx__XL: 100px;

// ================
// padding (vw)
// ================
$paddingVw__XS: 1vw;
$paddingVw__SM: 3vw;
$paddingVw__MD: 5vw;
$paddingVw__LG: 10vw;
$paddingVw__XL: 15vw;

// ================
// body min-width
// ================
$minWidth: 375px;

// ================
// view Size
// ================
$xs: '376px'; // for small screen mobile
$sm: '541px'; // for mobile screen
$md: '721px'; // for tablets
$lg: '1025px'; // for laptops
$xl: '1441px'; // for desktop / monitors
$xxl: '1921px'; // for big screens

// ================
// Break Points
// ================
$breakpoints: (
  'xs': 'screen and (min-width: #{$xs})',  // SmartPhone
  'sm': 'screen and (min-width: #{$sm})',  // Tablet
  'md': 'screen and (min-width: #{$md})',  // PC
  'lg': 'screen and (min-width: #{$lg})', // PC (Wide)
  'xl': 'screen and (min-width: #{$xl})', // PC (Wide)
  'xxl': 'screen and (min-width: #{$xxl})' // PC (Wide)
) !default;


$baseWidthSP : 375;
$baseWidthTB : 1024;
@function vw($pixelsVw,$size: 'sp') {
  @if($size == 'sp') {
    @return $pixelsVw / $baseWidthSP * 100vw;
  } @else if($size == 'tb') {
    @return $pixelsVw / $baseWidthTB * 100vw;
  } @else {
    @return $pixelsVw / $baseWidthSP * 100vw;
  }
}