@use '../../../scss/globals/' as g;
.header {
  display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  width: 100%;

  &__logo {
    width: 190px;
    z-index: 901;

    @include g.media('md') {
      width: 290px;
    }

    @include g.media('xl') {
      width: 290px;
    }
  }
  &__navigation {
    display: none;

    @media screen and (min-width: 1025px) {
      display: flex;
        justify-content: space-between;
      column-gap: 1vw;
    }
  }
}