@use '../../../../scss/globals/' as g;
.hamburger_wrapper {
  background: blue;
}
.hamburger {
  background: transparent;
  display: block;
  text-align: center;
  width: 30px;
  height: 30px;
  // position: fixed;
  // top: 3vw;
  // right: g.$paddingVw__MD;
  position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    right: -25px;
  z-index: 901;
  @media screen and (min-width: 768px) and (max-width: 1399px) {
    top: 35px;
    right: 35px;
  }
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  & > span {
    background-color: var(--color-white);
    display: inline-block;
    margin: auto;
    width: 40px;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    @media screen and (max-width: 768px) {
      width: g.vw(30);
    }
  }
  &[aria-expanded="true"] > .hamburger_line__top {
    transform: translate(0, 0) rotate(-45deg);
  }
  &[aria-expanded="true"] > .hamburger_line__middle {
    display: none;
  }
  &[aria-expanded="true"] > .hamburger_line__bottom {
    transform: translate(0, 0) rotate(45deg);
  }

  .hamburger_line__top,
  .hamburger_line__middle,
  .hamburger_line__bottom {
    transition: all .2s ease;
  }

  .hamburger_line__top {
    transform: translate(0, -10px);
    @media screen and (max-width: 768px) {
      transform: translate(0, g.vw(-5));
    }
  }
  .hamburger_line__middle {
    transform: translate(0, 0);
  }
  .hamburger_line__bottom {
    transform: translate(0, 10px);
    @media screen and (max-width: 768px) {
      transform: translate(0, g.vw(5));
    }
  }
}