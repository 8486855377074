@use "../../../scss/globals/" as g;
.footer_menu__wrap {
  margin-top: 25px;
}

.footer_menu, .footer_menu_sub {
  display: flex;
  flex-wrap: wrap;
    row-gap: 10px;
    justify-content: flex-start;
    
    @include g.media('md') {
      justify-content: flex-start;
      flex-direction: row;
      gap: 10px 30px;
      width: 550px;
    }

    @include g.media('lg') {
      justify-content: flex-end;
    }

  & li {
    a {
      font-size: 1.4rem;
    }
  }
}
.footer_menu li {
  @media screen and (max-width: 767px) {
    width: 48%;
  }
}
.footer_menu_sub {
  gap: 30px;
  margin-top: 30px;
  flex-direction: row;

  @include g.media('md') {
    gap: 25px;
  }

  & li {
    & a {
      font-size: 1.2rem;
    }
  }
}