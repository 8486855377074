@use "../../../scss/globals/" as g;

.footer {
  @media screen and (min-width: 768px) {
    background: url('/images/bg_04.png') no-repeat bottom center / cover;
    margin-top: 120px;
    padding-bottom: 140px;
    &__contents {
      width: 1160px;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      &__link {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 90px;
        &__box {
          background: var(--color-white);
          border-radius: 20px;
          width: 564px;
          a {
            display: block;
            padding: 40px 40px 25px 40px;
            transition: all .2s ease-out;
            &:hover {
              opacity: 0.6;
            }
            &::after {
              background: var(--color-blue) url('/svg/icon_arrow.svg') no-repeat center 50%;
              border-radius: 20px;
              content: '';
              display: block;
              width: 80px;
              height: 40px;
              margin-top: 62px;
              margin-left: auto;
            }
          }
          &:nth-of-type(1) a {
            background: url('/images/footer_contact_img_01.png') no-repeat top center / cover;
          }
          &:nth-of-type(2) a {
            background: url('/images/footer_contact_img_02.png') no-repeat top center / cover;
          }
          &__en {
            color: var(--color-green);
            font-size: 11px;
            font-weight: 600;
            &::before {
              content: '●　';
            }
          }
          &__title {
            font-size: 26px;
            font-weight: 700;
            margin: 0 0 10px;
          }
          &__text {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 100px;
      }
      &__copy {
        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            color: var(--color-white);
            font-size: 14px;
            font-weight: 700;
            margin-right: 2em;
            padding-left: 30px;
          }
          &__hoken {
            background: url('../../../../public/svg/icon_hoken.svg') no-repeat left 50%;
          }
          &__card {
            background: url('../../../../public/svg/icon_card.svg') no-repeat left 50%;
          }
          &__kids {
            background: url('../../../../public/svg/icon_kids.svg') no-repeat left 50%;
          }
          &__babybed {
            background: url('../../../../public/svg/icon_babybed.svg') no-repeat left 50%;
            padding-left: 25px!important;
          }
          &__parking {
            background: url('../../../../public/svg/icon_parking.svg') no-repeat left 50%;
            padding-left: 22px!important;
          }
        }
      }
      &__fixLink {
        width: 235px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 100;
        a {
          border-radius: 10px;
          color: var(--color-white);
          display: block;
          font-size: 11px;
          font-weight: 700;
          text-align: center;
          transition: all .2s ease-out;
          width: 110px;
          padding: 70px 0 20px;
          &:hover {
            opacity: 0.6;
          }
        }
        &__tenpo {
          background: #189BEF url('../../../../public/svg/icon_tenpo.svg') no-repeat top 25px center / 35px;
        }
        &__contact {
          background: #E847B7 url('../../../../public/svg/icon_contact.svg') no-repeat top 25px center / 35px;
        }
      }
    }
    &__toTop {
      background: url('../../../../public/svg/icon_arrow_toTop.svg') no-repeat right top 50% / 23px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      display: block;
      text-align: right;
      padding-right: 35px;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    padding-bottom: g.vw(55);
    &__contents {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
      &__link {
        &__box {
          width: 48%;
          border-radius: 20px;
          overflow: hidden;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: g.vw(70);
    &__contents {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
      &__link {
        flex-direction: column;
        align-items: center;
        &__box {
          & + & {
            margin-top: g.vw(20);
          }
        }
      }
      &__inner {
        border-bottom: 1px solid #6CA1F1;
        display: block;
        margin-bottom: 30px;
        padding-bottom: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    background: url('../../../../public/images/bg_04_sp.png') no-repeat bottom center / 100%;
    margin-top: g.vw(100);
    padding-bottom: g.vw(50);
    &__contents {
      padding-right: g.vw(24);
      padding-left: g.vw(24);
      padding-bottom: 50px;
      &__link {
        margin-bottom: g.vw(50);
        &__box {
          padding: g.vw(25);
          & + & {
            margin-top: g.vw(15);
          }
          &::after {
            background: var(--color-blue) url('../../../../public/svg/icon_arrow.svg') no-repeat center 50%;
            border-radius: g.vw(30);
            content: '';
            display: block;
            width: g.vw(80);
            height: g.vw(32);
            margin-top: g.vw(15);
            margin-left: auto;
          }
          &:nth-of-type(1) {
            background: url('../../../../public/images/footer_contact_img_01_sp.png') no-repeat 0 0 / 100%;
          }
          &:nth-of-type(2) {
            background: url('../../../../public/images/footer_contact_img_02_sp.png') no-repeat 0 0 / 100%;
          }
          &__en {
            color: var(--color-green);
            font-size: g.vw(11);
            font-weight: 600;
            &::before {
              content: '●　';
            }
          }
          &__title {
            font-size: g.vw(17);
            font-weight: 700;
            margin: 0 0 g.vw(5);
          }
          &__text {
            font-size: g.vw(14);
            font-weight: 700;
          }
        }
      }
      &__inner {
        margin-bottom: g.vw(30);
        padding-bottom: g.vw(30);
        border-bottom: 1px solid #6CA1F1;
      }
      &__copy {
        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            color: var(--color-white);
            font-size: g.vw(14);
            font-weight: 700;
            margin-right: 2em;
            margin-bottom: .5em;
            padding-left: g.vw(28);
          }
          &__hoken {
            background: url('../../../../public/svg/icon_hoken.svg') no-repeat left 50%;
          }
          &__card {
            background: url('../../../../public/svg/icon_card.svg') no-repeat left 50%;
          }
          &__kids {
            background: url('../../../../public/svg/icon_kids.svg') no-repeat left 50%;
          }
          &__babybed {
            background: url('../../../../public/svg/icon_babybed.svg') no-repeat left 50%;
          }
          &__parking {
            background: url('../../../../public/svg/icon_parking.svg') no-repeat left 3px top 50%;
          }
        }
      }
      &__fixLink {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: g.vw(12);
        padding-left: g.vw(12);
        position: fixed;
        left: 0;
        right: 0;
        bottom: g.vw(15);
        z-index: 100;
        a {
          border-radius: 10px;
          color: var(--color-white);
          display: block;
          font-size: g.vw(14);
          font-weight: 700;
          text-align: center;
          transition: all .2s ease-out;

          padding: g.vw(15) 0 g.vw(15) g.vw(18);
          &:hover {
            opacity: 0.6;
          }
        }
        &__tenpo {
          background: #189BEF url('../../../../public/svg/icon_tenpo.svg') no-repeat left g.vw(25) center;
          background-size: g.vw(18);
          width: g.vw(145);
        }
        &__contact {
          background: #E847B7 url('../../../../public/svg/icon_contact.svg') no-repeat left g.vw(25) center;
          background-size: g.vw(18);
          width: g.vw(185);
        }
      }
    }
    &__toTop {
      font-size: g.vw(11);
      background: url('../../../../public/svg/icon_arrow_toTop.svg') no-repeat right top 50% / 23px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      display: block;
      text-align: right;
      margin-top: g.vw(20);
      padding: g.vw(5) g.vw(35) g.vw(5) 0;
    }
  }
}
