@use '@globals' as g;

.header_navigation {
  &__items {
    font-size: g.auto_clamp(10, 1024, 15, 1440);
    display: flex;
      justify-content: flex-end;
    gap: 2vw;
    white-space: nowrap;
    padding-right: 25px;
  }
}