@use '@globals' as g;
.header {
  background-color: var(--color-background); 
  @include g.flex;
  align-items: center;
  padding: 0 2.5vw;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media screen and (min-width: 768px) {
    min-width: 768px;
    height: 100px;
  }

  @include g.media('lg') {
    padding: 0 1vw 0 3vw;
  }

  @media screen and (max-width: 767px) {
    padding: g.vw(10) g.$paddingVw__MD;
    max-height: g.vw(50);
    min-height: 57px;
  }
}