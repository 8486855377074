@use 'sass:math';
@use './variables' as var;

// Absolute center alignment
@mixin center-pos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  left: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// flex
@mixin flex {
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

@mixin flex-start {
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@mixin flex-center {
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

@mixin flex-end {
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

// clearfix
@mixin clearfix() {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}


// media queries
@mixin media($breakpoint) {
  @media #{map-get(var.$breakpoints, $breakpoint)} {
      @content;
  }
}

@mixin clamp_fontsize($fs_min, $width_small, $width_large, $fs_max) {
  $preferred_vw_base: math.div(($fs_max - $fs_min), ($width_large - $width_small));
  $preferred_vw: ($preferred_vw_base * 100) * 1vw;
  $preferred_rem: ($width_small * -1 * $preferred_vw_base + $fs_min) * .1rem;
  font-size: clamp($fs_min * .1rem, $preferred_rem + $preferred_vw, $fs_max * .1rem);
}

// $fs_min, $width_small, $fs_max and $width_large should be set with 'px'
@mixin auto_clamp($fs_min, $width_small, $fs_max, $width_large) {
  $preferred_vw_base: math.div(($fs_max - $fs_min), ($width_large - $width_small));
  $preferred_vw: ($preferred_vw_base * 100) * 1vw;
  $preferred_rem: ($width_small * -1 * $preferred_vw_base + $fs_min) * .1rem;
  font-size: clamp($fs_min * .1rem, $preferred_rem + $preferred_vw, $fs_max * .1rem);
}