@use "@globals" as g;

.br--sp {
  display: block;
  
  @include g.media('md') {
    display: none;
  }
}

.br--pc {
  display: none;

  @include g.media('md') {
    display: block;
  }
}
.pc-none {
  display: block;

  @include g.media('md') {
    display: none;
  }
}