@use "@globals" as g;

.price-table {
  background: var(--color-white);
  width: 100%;

  th,td {
    border: 1px solid #dee3e7;
    text-align: center;
    vertical-align: middle;
    padding: 13px;
  }

  & th {
    background: var(--color-waterBlue);
    color: var(--color-white);

    &.th_01 {
      background: var(--color-white);
      color: var(--color-waterBlue);
      font-weight: 500;
    }
  }

  @include g.media('xl') {
    margin: 60px 0 90px;
    th {
      font-size: 18px;
      padding: 20px;
    }
  }
}


.shop-open-table {
  font-size: 1.2rem;
  width: 100%;

  @include g.media('md') {
    font-size: 1.8rem;
  }

  & th, & td {
    border: 1px solid #DEE3E7;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    
    @include g.media('md') {
      padding: 2vw 2.5vw;
    }

    @include g.media('lg') {
      padding: 25px;
    }
  }

  & thead {
    & th {
      background-color: var(--color-waterBlue);
      color: white;
      text-align: center;

      &:first-child {
        width: 29%;
        @include g.media('lg'){
          width: 360px;
        }
      }
      &:not(:first-child) {
        width: calc((100% - 29%) / 8);
        @include g.media('lg'){
          width: calc((100% - 360px) / 8);
        }
      }
    }
  }

  & tbody {
    color: var(--color-waterBlue);

    & td {
      @include g.media('lg'){
        width: calc((100% - 360px) / 8);
      }
    }
  }

  &__index {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;

    @include g.media('md') {
      padding-left: 7vw;
    }

    @include g.media('lg') {
      flex-direction: row;
      padding-left: 60px;
    }
    
    @include g.media('xl') {
      width: 360px;
    }
  }
  &__index-time {
    border: 1px solid var(--color-waterBlue);
    border-radius: 30px;
    color: var(--color-waterBlue);
    display: none;
    padding: 5px 35px;
    text-align: center;
    margin-right: 15px;

    @include g.media('md') {
      display: block;
    }
  }
  &__note {
    display: block;
    font-size: 1rem;
    @include g.media('md') {
    font-size: 1.2rem;
    }
  }
}