@use "../../../scss/globals/" as g;
.footer_contact {
  @media screen and (min-width: 768px) {
    width: 381px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: g.vw(30);
    &__logo {
      width: g.vw(255);
    }
  }
}