@use "@globals" as g;

.u-link--faq {
  background-color: var(--color-blue);
  border-radius: 5px;
  color: var(--color-white);
  display: inline-block;
  font-size: 1.8rem;
  margin-top: 10px;
  padding: 10px 15px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
}