@use "@globals" as g;
.nav_link {
  &:hover {
    color: var(--color-secondary);
    text-decoration: underline;
  }
  &_sub::before,
  &_sub::after {
    transition: all .4s ease-out;
  }
  &_sub::after {
    background: var(--color-white);
    border: none;
    width: g.vw(7.5)!important;
    height: 2px!important;
    transform: rotate(0deg)!important;
    right: g.vw(8)!important;
  }
  &_sub::before {
    background: var(--color-white);
    content: '';
    display: block;
    border: none;
    width: g.vw(7.5)!important;
    height: 2px!important;
    transform: rotate(90deg)!important;
    margin: auto;
    position: absolute;
    right: g.vw(8);
    top: 0;
    bottom: 0;
  }
  &.active::before {
    transform: rotate(135deg)!important;
  }
  &.active::after {
    transform: rotate(45deg)!important;
  }
}

.sub_hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out; 
}

.sub_active {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.sub-links {
  padding-left: g.vw(25);
}

.sub-nav-link {
  border-top: 1px solid #0c20b7;
  border-right: 0!important;
  border-bottom: none !important;
  &:last-child {
  }
  a {
    position: relative;
    padding-left: 1rem;
    &:hover {
      color: var(--color-secondary);
    }
    &::before {
      color: #fff;
      content: "-";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
