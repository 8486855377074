@use "@globals" as g;
.footer__toTop {
  color: var(--color-white);
  background: url('/svg/icon_arrow_toTop.svg') no-repeat right top 50% / 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: block;
  text-align: right;
  padding-right: 35px;
  cursor: pointer;
  transition: all .2s ease-out;
  margin-top: g.vw(40);
  &:hover {
    opacity: 0.6;
  }
  @include g.media('md') {
    margin-top: g.vw(15);
  }
  @include g.media('lg') {
    margin-top: 0;
  }
}