.microcms-post {
  & h1 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  & h2 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1.8rem;
  }
  & h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.6rem;
  }
  & h4 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.4rem;
  }
  & h5 {
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  & blockquote {
    background-color: var(--color-gray);
    padding: 2rem;
    margin-bottom: 2em;
  }

  & ul {
    margin-bottom: 2em;
    & li {
      text-indent: 1em;
      margin-left: -1em;
      padding-left: 2rem;

      &::before {
        content: '・';
        margin-left: -2rem;
        padding-right: 2rem;
      }
    }
  }

  & ol {
    margin-bottom: 2em;
    & li {
      list-style: decimal;
      text-indent: 1em;
      margin-left: 2rem;
    }
  }

  & a * {
    color: var(--color-blue);
    text-decoration: underline;
  }
}

.color--red {color: var(--color-red);}
.color--blue {color: var(--color-blue);}
.color--green {color: var(--color-green);}
