@use "../../globals/" as g;

.o-content-body {
  margin: auto;
  width: 90vw;
  max-width: 900px;

  & h1 {
    font-size: 4.8rem;
    font-weight: 700;
    margin-top: g.$marginPx__XL;
  }
  & h2 {
    font-size: 3.6rem;
    font-weight: 700;
    margin-top: g.$marginPx__LG;
  }
  & h3 {
    font-size: 3rem;
    font-size: 700;
    margin-top: g.$marginPx__MD;
  }
  & h4 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: g.$marginPx__SM;
  }
  & h5, &h6 {
    font-weight: 700;
    margin-top: g.$marginPx__XS;
  }

  & table {
    & th, & td {
      border: 1px solid var(--color-primary);
      padding: g.$paddingPx__XS g.$paddingPx__SM;
    }
    & th {
      background-color: var(--color-background);
      font-weight: 700;
    }
  }
}