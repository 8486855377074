@use '@globals' as g;
.hamburger {

  display: block;

  &__navigation {
    background: var(--color-blue);
    box-shadow: 0px 2px 22px -10px #0d1b74 inset;
    transition: all .6s;
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      margin-top: 100px;
      padding-bottom: 100px;
    }
    @media screen and (max-width: 767px) {
      margin-top: clamp(57px,g.vw(42),g.vw(50));
      padding-bottom: clamp(57px,g.vw(42),g.vw(50));

    }
    @include g.media('lg') {
      justify-content: center;
    }
  
    &__open {
      left: 0;
    }
    &__close {
      left: 100%;
    }
  }
  @media screen and (min-width: 1025px) {
    display: none;
  }
}
.hamburgerLink {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: g.vw(20) g.vw(20) g.vw(10);
  a {
    border-radius: g.vw(10);
    color: var(--color-white);
    display: block;
    width: 48%;
    font-size: g.vw(14);
    font-weight: 700;
    text-align: center;
    padding: g.vw(10);
    &::before {
      content: '';
      display: inline-block;
      margin-right: g.vw(5);
      width: g.vw(18);
      height: g.vw(18);
      vertical-align: text-bottom;
    }
  }
  &__tenpo {
    background: #189BEF;
    &::before {
      background: url('../../../../public/svg/icon_tenpo.svg') no-repeat;
    }
  }
  &__contact {
    background: #E847B7;
    &::before {
      background: url('../../../../public/svg/icon_contact.svg') no-repeat;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    width: g.vw(80);
    margin: g.vw(10) auto g.vw(20);
    a {
      border-radius: g.vw(5);
      font-size: g.vw(5);
      padding: g.vw(3);
      &::before {
        width: g.vw(6);
        height: g.vw(6);
      }
    }
    &__tenpo {
      &::before {
        background-size: cover;
      }
    }
    &__contact {
      margin-top: g.vw(10);
      &::before {
        background-size: cover;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
    margin: 0 auto;
    a {
      border-radius: g.vw(5);
      font-size: g.vw(7);
      padding: g.vw(5);
      &::before {
        width: g.vw(9);
        height: g.vw(9);
      }
    }
    &__tenpo {
      &::before {
        background-size: cover;
      }
    }
    &__contact {
      &::before {
        background-size: cover;
      }
    }
  }
  &__recruit {
    padding: g.vw(10) g.vw(20) g.vw(20);
    a {
      background: rgba(255,255,255,.2) url('/images/icon_logo.png') no-repeat left 10px top 50% / 50px;
      border: 2px solid #0c20b7;
      border-radius: 15px;
      display: block;
      font-size: min(g.vw(20),32px);
      padding: 20px;
      padding-left: 80px;
    }
  }
  &__icon {
    margin-top: g.vw(20);
    padding: g.vw(10) g.vw(20) g.vw(20);
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      li {
        color: var(--color-white);
        background-position: left top 50%;
        font-size: min(g.vw(13),20px);
        font-weight: 700;
        margin-right: g.vw(20);
        margin-bottom: 1em;
        padding-left: 2em;
      }
      &__hoken {
        background: url('/svg/icon_hoken.svg') no-repeat;
      }
      &__card {
        background: url('/svg/icon_card.svg') no-repeat;
      }
      &__kids {
        background: url('/svg/icon_kids.svg') no-repeat;
      }
      &__babybed {
        background: url('/svg/icon_babybed.svg') no-repeat;
      }
      &__parking {
        background: url('/svg/icon_parking.svg') no-repeat;
      }
    }
  }
}
